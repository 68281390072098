import { defineAbility } from '@casl/ability'

export default function defineAbilityFor() {
  const userString =
    localStorage.getItem('currentUser') || JSON.stringify({ roles: [] })

  const user = JSON.parse(userString)

  return defineAbility((can) => {
    if (user.roles.includes('admin')) {
      can('manage', 'allUsers')
      can('manage', 'account_email_settings')

      can('invite', 'admin')
      can('invite', 'support')

      can('manage', 'accounts')
      can('manage', 'dashboard')
      can('manage', 'internal_users')

      can('change', 'smtp_settings')
      can('edit', 'units')
      can('filter', 'master_orders')

      can('view', 'suppliers')
    }

    if (
      user.roles.includes('account_owner') ||
      user.roles.includes('support')
    ) {
      can('manage', 'account_email_settings')
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('invite', 'account_owner')
      can('invite', 'company_admin')
      can('invite', 'cost_center_admin')
      can('invite', 'supervisor')
      can('invite', 'inspector')
      can('invite', 'accountant')
      can('invite', 'orderer')

      can('manage', 'dashboard')
      can('manage', 'members')
      can('manage', 'companies')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'items')
      can('manage', 'orders')
      can('export', 'master_orders')
      can('manage', 'cart')
      can('manage', 'cart_templates')
      can('manage', 'upload')
      can('manage', 'line_item_price')
      can('manage', 'cancel_subOrder')
      can('manage', 'expired_price_item')

      can('add', 'cost_centers')
      can('delete', 'cost_center')
      can('edit', 'cost_center')

      can('add', 'item')
      can('edit', 'item')

      can('add', 'item_to_cart')

      can('checkout', 'cart_order')
      can('resend', 'order_email')

      can('add', 'company')
      can('edit', 'company')
      can('delete', 'company')
      can('add', 'company_admin')

      can('add', 'supplier')
      can('edit', 'supplier')
      can('delete', 'supplier')
      can('import', 'csv_item')

      can('view', 'template_page')
      can('edit', 'template')

      can('add', 'cost_center_member')
      can('download', 'csv_template')
      can('apply', 'template')
      can('change', 'smtp_settings')

      can('edit', 'delivery')

      can('view', 'import_history')
      can('view', 'value')
      can('cancel', 'order')
      can('delete', 'delivery')
      can('edit', 'units')
      can('filter', 'master_orders')
    }

    if (user.roles.includes('support')) {
      can('change', 'account')
    }

    if (user.roles.includes('company_admin')) {
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('invite', 'cost_center_admin')
      can('invite', 'supervisor')
      can('invite', 'inspector')
      can('invite', 'accountant')
      can('invite', 'orderer')

      can('manage', 'dashboard')
      can('manage', 'companies')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'cart')
      can('manage', 'items')
      can('manage', 'orders')
      can('manage', 'members')
      can('export', 'master_orders')
      can('manage', 'cart_templates')
      can('manage', 'upload')
      can('manage', 'line_item_price')
      can('manage', 'cancel_subOrder')

      can('add', 'cost_centers')
      can('delete', 'cost_center')
      can('edit', 'cost_center')

      can('manage', 'expired_price_item')

      can('add', 'item')
      can('edit', 'item')

      can('add', 'item_to_cart')

      can('checkout', 'cart_order')
      can('resend', 'order_email')

      can('add', 'supplier')
      can('edit', 'supplier')
      can('delete', 'supplier')
      can('import', 'csv_item')

      can('view', 'template_page')
      can('edit', 'template')

      can('add', 'cost_center_member')
      can('download', 'csv_template')
      can('apply', 'template')

      can('edit', 'delivery')

      can('view', 'import_history')
      can('view', 'value')
      can('cancel', 'order')
      can('delete', 'delivery')
      can('filter', 'master_orders')
    }

    if (user.roles.includes('cost_center_admin')) {
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('invite', 'supervisor')
      can('invite', 'inspector')
      can('invite', 'accountant')
      can('invite', 'orderer')

      can('manage', 'dashboard')
      can('manage', 'companies')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'cart')
      can('manage', 'items')
      can('manage', 'orders')
      can('manage', 'members')
      can('export', 'master_orders')
      can('manage', 'cart_templates')
      can('manage', 'upload')
      can('manage', 'line_item_price')
      can('manage', 'cancel_subOrder')
      can('manage', 'expired_price_item')

      can('add', 'item')
      can('edit', 'item')

      can('add', 'item_to_cart')

      can('checkout', 'cart_order')
      can('resend', 'order_email')

      can('add', 'supplier')
      can('edit', 'supplier')
      can('delete', 'supplier')
      can('import', 'csv_item')

      can('view', 'template_page')
      can('edit', 'template')

      can('add', 'cost_center_member')
      can('download', 'csv_template')
      can('apply', 'template')

      can('edit', 'delivery')

      can('view', 'import_history')
      can('view', 'value')
      can('cancel', 'order')
      can('delete', 'delivery')
    }

    if (user.roles.includes('supervisor')) {
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('manage', 'dashboard')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'items')
      can('manage', 'orders')
      can('export', 'master_orders')
      can('manage', 'cart_templates')
      can('manage', 'upload')
      can('manage', 'line_item_price')
      can('manage', 'cancel_subOrder')

      can('manage', 'expired_price_item')

      can('add', 'item')
      can('edit', 'item')

      can('add', 'item_to_cart')

      can('resend', 'order_email')

      can('add', 'supplier')
      can('edit', 'supplier')
      can('delete', 'supplier')
      can('import', 'csv_item')

      can('view', 'template_page')
      can('edit', 'template')
      can('download', 'csv_template')
      can('apply', 'template')
      can('view', 'value')

      can('edit', 'delivery')
      can('cancel', 'order')
      can('delete', 'delivery')
    }

    if (user.roles.includes('inspector')) {
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('manage', 'dashboard')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'items')
      can('manage', 'orders')
      can('export', 'master_orders')
      can('manage', 'cart_templates')
      can('manage', 'upload')

      can('edit', 'item')

      can('resend', 'order_email')

      can('view', 'template_page')
      can('apply', 'template')
      can('view', 'value')

      can('edit', 'delivery')
      can('delete', 'delivery')
    }

    if (user.roles.includes('accountant')) {
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('manage', 'dashboard')
      can('manage', 'suppliers')
      can('manage', 'settings')
      can('manage', 'items')
      can('manage', 'orders')
      can('manage', 'cart_templates')
      can('manage', 'upload')

      can('edit', 'item')
      can('apply', 'template')
      can('view', 'value')
    }

    if (user.roles.includes('orderer')) {
      can('manage', 'dashboard')
      can('see', 'profileScopes')
      can('change', 'profileScopes')
      can('manage', 'items')
      can('manage', 'cart')
      can('checkout', 'cart_order')
      can('manage', 'orders')

      can('manage', 'cart_templates')
      can('apply', 'template')
      can('add', 'item_to_cart')

      can('view', 'suppliers')
    }
  })
}
