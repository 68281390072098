import itemsApi from '@/services/api/items'
import settingsApi from '@/services/api/settings'
import lookupsApi from '@/services/api/lookups'
import { uniqBy, sortBy, isEmpty } from 'lodash'
import userPreferencesApi from '@/services/api/user-preferences'
import { viewTypeToIconName } from '@/services/helpers'

export const namespaced = true

export const state = {
  count: 0,
  total_count: 0,
  total_items_in_cart: 0,
  current_page: 0,
  pages: 0,
  per_page: 0,
  q: null,
  view_type: 'full-view-icon_24px',
  filters: {
    brick_names: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
      demoFeatureName: 'brick_names_demo',
    },
    bls_groups: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
      isDemoFeature: true,
    },
    suppliers: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
    allergens: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
    additives: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
    status: {
      value: [],
      options: [
        { name: '1', type: 'status' },
        { name: '2', type: 'status' },
        { name: '3', type: 'status' },
        { name: '0', type: 'status' },
      ],
      type: 'checkbox',
    },
    item_types: {
      value: [],
      loading: false,
      options: [],
      type: 'checkbox',
      withAsyncOptions: true,
    },
    family_names: {
      value: [],
      loading: false,
      options: [],
      isHidden: true,
    },
    replacement_group_id: {
      value: [],
      loading: false,
      options: [],
      isHidden: true,
    },
  },
  sort: {
    value: { name: 'created_at_asc', attr: 'spree_prices.variant_id' },
    options: [
      { name: 'created_at_asc', attr: 'spree_prices.variant_id' },
      { name: 'created_at_desc', attr: '-spree_prices.variant_id' },
      {
        name: 'name_asc',
        attr: 'spree_product_translations.name',
      },
      { name: 'name_desc', attr: '-spree_product_translations.name' },
      { name: 'price_asc', attr: 'spree_prices.amount' },
      { name: 'price_desc', attr: '-spree_prices.amount' },
      { name: 'status_asc', attr: 'spree_products.status' },
      { name: 'status_desc', attr: '-spree_products.status' },
    ],
    selected: false,
    type: 'radio',
  },
  list: [],
  baseUnits: [],
  item_total_quantity: 0.0,
  infiniteId: +new Date(),
  isBlankState: null,
}

export const mutations = {
  ADD_ITEMS_DATA(
    state,
    { data: { items, view_type, ...pagination }, noFilters }
  ) {
    state.list = uniqBy([...state.list, ...items], 'id')
    state.current_page += 1
    state.total_count = pagination.total_count
    state.view_type = viewTypeToIconName(view_type)
    state.isBlankState = noFilters && pagination.total_count === 0
  },
  APPLY_FILTER(state, { type, filterItem }) {
    if (
      filterItem.id &&
      state.filters[type].value.some((itm) => itm.id === filterItem.id)
    ) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem.id !== filterItem.id
        }
      )
    } else if (
      !filterItem.id &&
      type === 'status' &&
      state.filters[type].value.some((itm) => itm.name === filterItem.name)
    ) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem.name !== filterItem.name
        }
      )
    } else {
      state.filters[type].value.push(filterItem)
    }
  },
  CLEAR_ALL_FILTERS(state) {
    state.filters = Object.entries(state.filters).reduce(
      (acc, [key, filter]) => {
        acc[key] = { ...filter, value: [] }
        return acc
      },
      {}
    )
  },
  CLEAR_FILTER(state, filterItem) {
    state.filters[filterItem.type].value = state.filters[
      filterItem.type
    ].value.filter((item) => {
      return item.name !== filterItem.name
    })
  },
  CLEAR_SPECIFIED_FILTER_GROUP(state, filterGroup) {
    state.filters[filterGroup].value = []
  },
  RESET_LOADED_ITEMS(state) {
    state.list = []
    state.current_page = 0
    state.infiniteId += 1
  },
  SET_BASE_UNITS(state, { packaging_types }) {
    state.baseUnits = packaging_types
  },
  SET_FILTER_LOADING(state, { value, type }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { data, type }) {
    state.filters[type].options = data.map((item) => {
      return {
        id: item.id,
        name: item.name,
        code: item.code,
        type,
      }
    })
  },
  SET_SORT(state, value) {
    state.sort.value = value
  },
  SET_VIEW_TYPE(state, value) {
    state.view_type = value
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
  TOGGLE_MOBILE_SORT(state, item) {
    state.sort.selected = !item.selected
  },
  UPDATE_ITEM(state, { item }) {
    state.list = state.list.map((oldItem) => {
      if (oldItem.variant_id !== item.variant_id) return oldItem

      return { ...oldItem, ...item }
    })
  },
  REMOVE_LISTS_ITEM(state, { index }) {
    if (index >= 0) state.list.splice(index, 1)
  },
  UPSERT_LISTS_ITEM(state, { index, item }) {
    if (index >= 0) state.list.splice(index, 0, { ...item })
  },
}

export const actions = {
  applyFilter({ commit }, { filterItem, type }) {
    commit('APPLY_FILTER', { filterItem, type })
    commit('RESET_LOADED_ITEMS')
  },
  clearAllFilters({ commit }) {
    commit('CLEAR_ALL_FILTERS')
  },
  clearFilter({ commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
  },
  clearSpecifiedFilterGroup({ commit }, filterItem) {
    commit('CLEAR_SPECIFIED_FILTER_GROUP', filterItem.type)
  },
  filterItems({ commit }) {
    commit('RESET_LOADED_ITEMS')
  },
  filterMobileSort({ commit }, value) {
    commit('SET_SORT', value)
  },
  async getBaseUnits({ commit }) {
    const response = await settingsApi.packaging_types.index({ all: true })

    commit('SET_BASE_UNITS', response.data)
  },
  async loadItems({ commit, state, getters }, infiniteLoaderContext) {
    const {
      current_page,
      q,
      sort: { value },
      filters: {
        status,
        suppliers,
        bls_groups,
        brick_names,
        allergens,
        additives,
        family_names,
        item_types,
        replacement_group_id,
      },
    } = state

    const startInfiniteId = state.infiniteId

    try {
      const response = await itemsApi.index({
        page: current_page + 1,
        q,
        sort: value && value.attr,
        status: status.value.map((item) => item.name).join(','),
        supplier_tenant_ids: suppliers.value.map((item) => item.id),
        without_allergens: allergens.value.map((item) => item.code).join(','),
        without_additives: additives.value.map((item) => item.code).join(','),
        brick_names: [
          ...brick_names.value.map((item) => item.name),
          ...bls_groups.value.map((item) => item.name),
        ].join(','),
        family_names: [...family_names.value.map((item) => item.name)].join(
          ','
        ),
        item_types_ids: item_types.value.map((item) => item.id),
        replacement_group_id: replacement_group_id.value[0],
      })

      const endInfiniteId = state.infiniteId

      if (startInfiniteId !== endInfiniteId) return

      if (response.data.pages <= response.data.current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      commit('ADD_ITEMS_DATA', {
        data: response.data,
        noFilters: isEmpty(state.q) && isEmpty(getters.appliedFilters),
      })
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async loadFilterOptions({ commit, state }, type) {
    commit('SET_FILTER_LOADING', { value: true, type })

    let options = []

    switch (type) {
      case 'brick_names': {
        const response = await lookupsApi.product_groups.index({
          group_type: 'custom',
          only_assigned: true,
          all: true,
        })
        options = response.data.product_groups
        break
      }
      case 'bls_groups': {
        const response = await lookupsApi.product_groups.index({
          group_type: 'bls',
          only_assigned: true,
          all: true,
        })
        options = response.data.product_groups
        break
      }
      case 'item_types': {
        const response = await lookupsApi.item_types.index()
        options = response.data.item_types
        break
      }
      case 'suppliers': {
        const response = await lookupsApi.supplier_tenants.index()
        options = response.data.supplier_tenants
        break
      }
      case 'status': {
        options = state.filters.status.options
        break
      }
      default: {
        const response = await settingsApi[type].index({ all: true })
        options = response.data[type]
      }
    }

    commit('SET_FILTER_OPTIONS', {
      data: sortBy(options, (n) => n.name.toLowerCase(), 'name'),
      type,
    })

    commit('SET_FILTER_LOADING', { value: false, type })
  },
  async updateViewType({ commit }, value) {
    const mappings = {
      'full-view-icon_24px': 'items_full',
      'card-view-icon_24px': 'items_card',
      actions_list_view: 'items_list',
    }

    await userPreferencesApi.update({ items_view_type: mappings[value] })

    commit('SET_VIEW_TYPE', value)
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
  toggleMobileSort({ commit }, sortState) {
    commit('TOGGLE_MOBILE_SORT', sortState)
  },
  async createItem(_ctx, params) {
    return await itemsApi.create(params)
  },
  async updateItem({ commit, state }, { id, ...params }) {
    const index = state.list.findIndex((item) => item.id === id)
    const item = index >= 0 ? state.list[index] : {}
    commit('REMOVE_LISTS_ITEM', { index })

    try {
      const response = await itemsApi.update(id, params)
      commit('UPSERT_LISTS_ITEM', { index, item: response.data })
      return response.data
    } catch (error) {
      commit('UPSERT_LISTS_ITEM', { index, item })
      throw error
    }
  },
  async updateItemPrice({ commit }, { id, price }) {
    const response = await itemsApi.prices.create(id, { price })

    commit('UPDATE_ITEM', { item: response.data })

    return response.data
  },
  async verifyItemUnits({ commit }, { variantId }) {
    const response = await itemsApi.verify_units.create(variantId)

    commit('UPDATE_ITEM', response.data)

    return response.data
  },
}

export const getters = {
  appliedFilters: (state) => {
    return Object.entries(state.filters).flatMap(
      // eslint-disable-next-line no-unused-vars
      ([_key, filterValue]) => filterValue.value
    )
  },
  filterElems: (state, _getters, _rootState, rootGetters) => {
    const demoFeaturesEnabled = rootGetters['currentUser/demoFeaturesEnabled']

    const filters = Object.entries(state.filters).flatMap(([key, value]) => {
      const customName =
        demoFeaturesEnabled && value.demoFeatureName
          ? value.demoFeatureName
          : key
      return { name: key, customName, ...value }
    })

    return filters.filter(
      (filterItem) =>
        !filterItem.isHidden &&
        (!filterItem.isDemoFeature ||
          (filterItem.isDemoFeature && demoFeaturesEnabled))
    )
  },
  getItemById: (state) => (id) => {
    return state.list.find((item) => item.id === id)
  },
  lineItemFromPrice: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    return currentCart.line_items.find(
      (lineItem) =>
        lineItem.supplier_tenant_id === item.supplier_tenant_id &&
        lineItem.variant_id === item.variant_id &&
        lineItem.state === 'draft'
    )
  },
  qtyInCart: (_state, getters) => (item) => {
    const lineItem = getters.lineItemFromPrice(item)

    return lineItem ? lineItem.quantity : 0
  },
  qtyInCartIncludingOffers: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    return currentCart.line_items
      .filter(
        (lineItem) =>
          (lineItem.supplier_tenant_id === item.supplier_tenant_id &&
            lineItem.variant_id === item.variant_id) ||
          item.offers
            .map((offer) => offer.variant_id)
            .includes(lineItem.variant_id)
      )
      .map((lineItem) => lineItem.quantity)
      .reduce((qty, acc) => acc + qty)
  },
  isHiddenOfferInCart: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    return currentCart.line_items.some((lineItem) => {
      return (
        lineItem.variant_id === item.variant_id &&
        lineItem.supplier_tenant_id !== item.supplier_tenant_id
      )
    })
  },
  hasOffers: () => (item) => {
    return item.offers && item.offers.length > 1
  },
  averageOffersPrice: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    const items = item.offers.filter((offer) => {
      return currentCart.line_items.some(
        (lineItem) =>
          lineItem.variant_id === offer.variant_id &&
          lineItem.supplier_tenant_id === offer.supplier_tenant_id
      )
    })

    const sum = items
      .map((item) => parseFloat(item.amount))
      .reduce((amount, acc) => acc + amount)

    const average = sum / items.length

    return average
  },
  itemSuppliersInCart: (_state, _getters, rootState) => (item) => {
    const { currentCart } = rootState.currentUser.currentUser

    const items = item.offers.filter((item) =>
      currentCart.line_items.some((lineItem) => {
        return (
          lineItem.variant_id === item.variant_id &&
          lineItem.supplier_tenant_id === item.supplier_tenant_id
        )
      })
    )

    return items.map((item) => item.supplier_name)
  },
}
