import ordersApi from '@/services/api/orders'

export const namespaced = true

export const state = {
  resource: null,
  versions: {
    infiniteId: +new Date(),
    list: [],
    pages: 0,
    per_page: 0,
    total_count: 0,
    count: 0,
    current_page: 0,
  },
  master_number: null,
  fetching_order: true,
}

export const mutations = {
  ADD_ORDER_VERSIONS_DATA(state, { versions, ...paginationMeta }) {
    state.versions.list = [...state.versions.list, ...versions]
    state.versions.current_page += 1
    state.versions.total_count = paginationMeta.total_count
  },
  RESET_ORDER_VERSIONS(state) {
    state.versions.list = []
    state.versions.current_page = 0
    state.versions.total_count = 0
  },
  SET_ORDER(state, order) {
    state.resource = order
    state.fetching_order = false
  },
  SET_MASTER_ORDER_NUMBER(state, number) {
    state.master_number = number
  },
  UPDATE_CURRENT_ORDER(state, order) {
    state.resource = order
  },
  UPDATE_ORDER(state, order) {
    state.resource = { ...state.resource, ...order }
  },
}

export const actions = {
  async getOrder({ commit }, number) {
    const response = await ordersApi.show(number)

    commit('SET_ORDER', response.data)
    commit('SET_MASTER_ORDER_NUMBER', response.data.master_order_number)
  },
  setOrder({ commit }, order) {
    commit('SET_ORDER', order)
  },
  async updateOrderAttachment({ commit }, formData) {
    const response = await ordersApi.attachments.create(formData)

    commit('SET_ORDER', response.data)
  },
  async deleteOrderAttachment({ commit }, id) {
    const response = await ordersApi.attachments.delete(id)

    commit('SET_ORDER', response.data)
  },
  async resendOrderEmail({ commit }, order) {
    const response = await ordersApi.confirmation.update({
      order_id: order.id,
    })
    commit('UPDATE_ORDER', response.data)
    return response.data
  },
  async cancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      canceled: true,
      send_order_cancelation_email: send_order_cancelation_email,
    })
    commit('SET_ORDER', response.data)
  },
  async unCancelSubOrder({ commit }, { order, send_order_cancelation_email }) {
    const response = await ordersApi.cancel.update(order.id, {
      send_order_cancelation_email: send_order_cancelation_email,
      canceled: false,
    })
    commit('SET_ORDER', response.data)
  },
  async processOrder({ commit }, { order_id, deliver_at }) {
    const response = await ordersApi.process.update(order_id, {
      deliver_at,
    })
    commit('SET_ORDER', response.data)
    return response.data
  },
  async loadOrderVersions({ commit, state }, params) {
    const { current_page } = state.versions
    const { paginationContext, id } = params

    try {
      const response = await ordersApi.versions.index(id, {
        page: current_page + 1,
      })

      if (response.data.pages <= current_page) {
        paginationContext.complete()
      } else {
        paginationContext.loaded()
      }

      commit('ADD_ORDER_VERSIONS_DATA', response.data)
    } catch (error) {
      paginationContext.error()
    }
  },
  async resetVersions({ commit }) {
    commit('RESET_ORDER_VERSIONS')
  },
}
