const plugin = require('tailwindcss/plugin')

module.exports = {
  purge: {
    content: ['./src/**/*.html', './src/**/*.{js,jsx,ts,tsx,vue}'],
    options: {
      safelist: [
        'z-1',
        'z-2',
        'z-3',
        'z-4',
        'z-5',
        'z-6',
        'z-7',
        'z-8',
        'z-9',
        'md:z-40',
        'md:z-30',
        'bg-conifer',
        'bg-fs-red',
        'bg-fs-violet',
        'bg-fs-mid-blue',
        'bg-fs-corporate',
        'bg-fs-orange',
        'border-conifer',
        'border-fs-corporate',
        'border-fs-mid-blue',
        'border-fs-red',
        'border-fs-orange',
        'border-outrageous-orange',
        'border-mine-shaft',
        'p-3.5',
        'p-8',
        'text-oslo-gray',
        'text-fs-mid-blue',
        'w-312px',
        'w-600px',
        'w-112',
        'md:w-112',
        'w-160',
        'md:w-160',
        'md:w-400px',
        'h-15',
        'h-24',
        'rounded-10',
        'top-1',
        'top-12',
        'top-15',
        'top-16.5',
      ],
    },
  },
  theme: {
    customForms: (theme) => ({
      default: {
        checkbox: {
          width: theme('spacing.4'),
          height: theme('spacing.4'),
          borderRadius: theme('borderRadius.sm'),
          color: theme('colors.fs-corporate'),
          borderWidth: theme('borderWidth.2'),
          borderColor: theme('colors.medium-gray'),
        },
        radio: {
          width: theme('spacing.4'),
          height: theme('spacing.4'),
          color: theme('colors.fs-corporate'),
          borderWidth: theme('borderWidth.2'),
          borderColor: theme('colors.medium-gray'),
        },
      },
    }),
    fontFamily: {
      regular: ['Poppins-Regular'],
      medium: ['Poppins-Medium'],
      light: ['Poppins-Light'],
      semibold: ['Poppins-SemiBold'],
      bold: ['Poppins-Bold'],
    },
    screens: {
      xs: '375px',
      sm: '768px',
      md: '768px',
      lg: '913px',
      1000: '1000px',
      xl: '1024px',
      '2xl': '1440px',
      '4xl': '1789px',
      '4.5xl': '1920px',
    },
    extend: {
      opacity: {
        90: '.9',
      },
      inset: {
        16.5: '4.125rem',
        '5px': '0.313rem',
        fully: '-100%',
      },
      minWidth: {
        6: '1.5rem' /* 24px */,
        21: '4.5rem',
        '80px': '80px',
        'modal-tabs-tablet': '180px',
        0: '0',
        '100px': '100px',
        '154px': '154px',
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        full: '100%',
      },
      width: {
        4.5: '1.125rem' /* 18px */,
        6.5: '1.625rem' /* 26px */,
        7.5: '1.875rem',
        8.2: '2.125rem',
        13: '3.375rem' /* 54px */,
        15: '4.25rem',
        16: '4rem', // 64px
        17: '4.25rem', // 68px
        19.5: '4.875rem', // 78px
        21: '4.5rem',
        21.5: '5.313rem',
        21.7: '5.5rem', //88px
        21.8: '5.625rem',
        22: '5.813rem',
        23: '5.75rem', // 92px
        22.6: '5.688rem',
        25: '6.25rem', // 100px
        26: '6.5rem',
        26.8: '6.813rem',
        27.5: '6.875rem' /* 110px */,
        29: '7.25rem',
        30: '7.313rem',
        31: '7.5rem' /* 120px */,
        32: '8rem' /* 128px */,
        32.5: '8.125rem' /* 130px */,
        35: '8.75rem',
        37: '9.25rem' /* 148px */,
        39: '9.75rem',
        39.5: '9.835rem',
        40.5: '10.125rem',
        40.2: '10.219rem',
        45: '11.25rem',
        45.5: '11.375rem' /* 182px */,
        47: '11.75rem', // 188px
        48.5: '12.125rem',
        49: '12.25rem',
        50: '12.5rem', // 200px
        51: '12.75rem', // 204px
        52.5: '13.125rem',
        53: '13.25rem' /* 212px */,
        54: '13.5rem' /* 216px */,
        57: '14.438rem',
        61: '15.25rem',
        62: '15.5rem',
        66: '16.5rem',
        68: '17rem',
        69: '17.25rem',
        78: '19.5rem' /* 312px */,
        87.5: '21.875rem' /* 350 px */,
        111: '27.75rem' /* 444px */,
        112: '28rem' /* 448px */,
        120: '32rem',
        128.5: '32.125rem' /* 514px */,
        152: '38rem' /* 608px */,
        156: '39rem' /* 624px */,
        158: '39.5rem' /* 632px */,
        160: '40rem' /* 640px */,
        186: '46.5rem' /* 744px */,
        '230px': '230px',
        '236px': '236px',
        '284px': '284px',
        '312px': '312px',
        '400px': '400px',
        '560px': '560px',
        '600px': '600px',
        'item-filters-tablet': '375px',
        'item-filters-desktop': '300px',
        'item-full-card-image': '134px',
        'items-subheader-desktop': '790px',
        'subheader-desktop': '992px',
        'user-profile-menu': '280px',
        'tablet-page-container': '736px',
        'desktop-page-container': '1024px',
        'desktop-page-min-container': '1156px',
        'desktop-page-max-container': '1352px',
        'desktop-page-wide-container': '1108px',
        'desktop-page-wide-min-container': '1240px',
        'cart-sidebar': '600px',
        'desktop-base-modal-v2-content': '704px',
        'outer-min-desktop-container': 'var(--desktop-min-width)',
        'outer-max-desktop-container': 'var(--desktop-max-width)',
      },
      maxHeight: {
        '3/4': '75%',
        '400px': '400px',
        '628px': '628px',
        '750px': '750px',
        '850px': '850px',
      },
      maxWidth: {
        1: '1px',
        27.5: '6.875rem' /* 110px */,
        32: '8rem',
        40: '10rem',
        51: '12.75rem', // 204px,
      },
      minHeight: {
        2: '2.5rem',
        12: '3rem',
        18: '4.5rem',
        41.25: '10.313rem',
        56: '14rem', // 224px
        88: '22rem',
        '400px': '400px',
      },
      height: {
        4.5: '1.125rem' /* 18px */,
        5.4: '1.438rem',
        6.5: '1.625rem' /* 26px */,
        7.5: '1.875rem',
        8.2: '2.125rem' /* 34px */,
        11.8: '2.875rem',
        13: '3.375rem' /* 54px */,
        15: '3.75rem', // 60px
        16.5: '4.125rem', // 66px
        18: '4.5rem',
        26: '6.5rem',
        40.1: '10.188rem',
        45: '11.25rem',
        48.5: '12.125rem',
        49: '12.25rem',
        52.5: '13.125rem',
        57: '13.5rem',
        58.1: '14.1rem',
        74: '20.75rem',
        87: '21.75rem',
        119: '29.75rem',
        'item-full-card-image': '134px',
        '60px': '60px',
        '40px': '40px',
        '100px': '100px',
        '154px': '154px',
        '300px': '300px',
        '400px': '400px',
        '90vh': '90vh',
      },
      margin: {
        '-7': '-1.75rem',
        '-28': '-7rem',
        7.5: '1.875rem',
        22: '5.5rem',
        13: '3.25rem' /* 52px */,
      },
      fontSize: {
        '10px': '10px',
        '2.5xl': '1.75rem',
        '3.5xl': [
          '2rem',
          {
            lineHeight: '2.675rem',
          },
        ],
        xxs: '.625rem',
        allergen: '2rem',
      },
      lineHeight: {
        14: '3.5rem',
      },
      padding: {
        1.25: '0.313rem',
        1.75: '0.438rem',
        2.5: '0.688rem',
        4.5: '1.125rem',
        16.5: '4.125rem',
        22: '5.5rem',
      },
      spacing: {
        'default-modal': '489px',
        14: '3.5rem',
        15: '3.75rem' /* 60px */,
        17.25: '4.313rem' /* 68px */,
        26: '6.5rem',
        67: '16.75rem',
        68: '17rem',
        72: '18rem',
      },
      borderRadius: {
        2: '2px',
        3: '0.188rem',
        4: '4px',
        6: '6px',
        10: '10px',
        12: '12px',
        15: '15px',
        32: '32px',
      },
      boxShadow: {
        'md-up':
          '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
        '3xl': '0px 2px 6px rgba(22, 22, 22, 0.25)',
        'side-menu': '0px 19.53454px 39.06908px 0px rgba(55, 55, 64, 0.15)',
        'empty-view-button':
          '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
        'toggle-button': 'var(--shadow-s1-toggle-button)',
      },
      zIndex: {
        1: 1,
        2: 2,
        3: 3,
        4: 4,
        5: 5,
        6: 6,
        7: 7,
        8: 8,
        9: 9,
        35: 35,
        60: 60,
        70: 70,
      },
      colors: {
        'alabaster-secondary': '#fafafa',
        'alabaster-light-opacity-50': '#fff6',
        'alabaster-dark-opacity-50': '#16161880',
        'arrow-town': '#9a8e6c',
        apple: '#54bf36',
        apricot: '#ec9261',
        backdrop: '#000',
        'bright-red': '#ff3634',
        'cod-gray': '#161616',
        conifer: '#97E467',
        copper: '#c27344',
        'dodger-blue': '#1b91ff',
        'fs-corporate': '#00C67D',
        'fs-coldgrey': '#647593',
        'fs-green': '#66BB6A',
        'fs-light-green': '#66BB6A26',
        'fs-light-blue': '#72deff',
        'fs-light-coldgrey': '#aac0cd',
        'fs-mid-blue': '#5db1ff',
        'fs-mid-light-blue': '#5DB1FF26',
        'fs-orange': '#ff8159',
        'fs-purple': '#5d77ff',
        'fs-violet': '#b15dff',
        'fs-red': '#ff5959',
        'fs-light-red': '#FF595926',
        'fs-silver-dark': '#595959',
        'fs-yellow': '#ffcf44',
        gainsboro: '#dadada',
        'header-text': '#f4f4f4',
        iron: '#e1e1e2',
        'oslo-gray': '#828b94',
        'outrageous-orange': '#fc5c42',
        'permanent-black': '#111', // Button.vue must be replaced
        'permanent-white': '#fff',
        sapphire: '#28429d',

        // Gray shades (dark/light theme specific)

        alabaster: 'var(--color-alabaster)',
        contrast: 'var(--color-contrast)',
        'default-bg': 'var(--color-default-bg)',
        'fs-canary': 'var(--color-fs-canary)',
        gallery: 'var(--color-gallery)',
        'gallery-input': 'var(--color-gallery-input)',
        'input-bg': 'var(--color-input-bg)',
        'input-label': 'var(--color-input-label)',
        'medium-gray': 'var(--color-medium-gray)',
        'mine-shaft': 'var(--color-mine-shaft)',
        silver: 'var(--color-silver)',
        white: 'var(--color-white)',

        // Redesign colors

        // Primary colors
        // TODO: After Vue 3 upgrade rename s1-blue- to s1-primary-
        's1-blue-100': 'var(--color-s1-blue-100)',
        's1-blue-darker': 'var(--color-s1-blue-darker)',
        's1-blue-60': 'var(--color-s1-blue-60)',
        's1-blue-50': 'var(--color-s1-blue-50)',
        's1-blue-20': 'var(--color-s1-blue-20)',
        's1-blue-10': 'var(--color-s1-blue-10)',

        // Other colors
        's1-main-100': 'var(--color-s1-main-100)',
        's1-gray-100': 'var(--color-s1-gray-100)',
        's1-gray-80': 'var(--color-s1-gray-80)',
        's1-gray-60': 'var(--color-s1-gray-60)',
        's1-gray-50': 'var(--color-s1-gray-50)',
        's1-gray-20': 'var(--color-s1-gray-20)',

        's1-gray-100-light': '#373740',
        's1-gray-80-light': '#6f767e',
        's1-gray-20-light': '#f4f4f4',

        's1-red-100': '#ff6a55',
        's1-purple-100': '#cabdff',
        's1-blue-secondary-100': '#b1e5fc',
        's1-green-100': '#b5e4ca',
        's1-yellow-100': '#ffd88d',
        's1-orange-100': '#ffbc99',
      },
    },
  },
  variants: {
    extend: {
      backgroundColor: ['disabled', 'active', 'checked'],
      borderColor: ['checked'],
      textColor: ['checked'],
      opacity: ['disabled'],
      ringColor: ['hover'],
    },
  },
  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/line-clamp'),
    plugin(function ({ addComponents }) {
      const sharedClasses = {
        '.scrolable-filter-input': {
          width: '200px !important',
        },
        '@media screen and (min-width: 1000px)': {
          '.scrolable-filter-input': {
            width: 'auto !important',
          },
        },
        '.w-inherit': {
          width: 'inherit',
        },
        '.scrollbar-none': {
          '-ms-overflow-style': 'none',
          'scrollbar-width': 'none',
        },
        '.scrollbar-none::-webkit-scrollbar': {
          display: 'none',
        },
      }

      addComponents(sharedClasses)
    }),
  ],
}
