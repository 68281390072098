import apiClient from '@/services/api'

export default {
  index() {
    return apiClient.get('api/carts')
  },
  create(params) {
    return apiClient.post('api/cart', params)
  },
  show(params) {
    return apiClient.get('api/cart', { params })
  },
  update(id, params) {
    return apiClient.put(`api/carts/${id}`, params)
  },
  delete(id) {
    return apiClient.delete(`api/carts/${id}`)
  },
  externalSync() {
    return apiClient.post('api/cart/external_sync')
  },
  lineItems: {
    update(id, params) {
      return apiClient.put(`api/cart/line_items/${id}`, params)
    },
    delete(id, params = null) {
      return apiClient.delete(`api/cart/line_items/${id}`, { data: params })
    },
    checks: {
      update(line_item_id, params) {
        return apiClient.put(
          `api/cart/line_items/${line_item_id}/check`,
          params
        )
      },
    },
    total_prices: {
      update(line_item_id, params) {
        return apiClient.put(
          `api/cart/line_items/${line_item_id}/total_price`,
          params
        )
      },
    },
    unit_prices: {
      update(line_item_id, params) {
        return apiClient.put(
          `api/cart/line_items/${line_item_id}/unit_price`,
          params
        )
      },
    },
  },
  optimization: {
    show(params) {
      return apiClient.get('api/cart/optimization', { params })
    },
    update(params) {
      return apiClient.put('api/cart/optimization', params)
    },
    replacement_groups: {
      show(replacement_group_id, params) {
        return apiClient.get(
          `api/cart/optimization/replacement_groups/${replacement_group_id}`,
          { params }
        )
      },
      short: {
        show(replacement_group_id) {
          return apiClient.get(
            `api/cart/optimization/replacement_groups/${replacement_group_id}/short`
          )
        },
      },
    },
    master_orders: {
      create(params) {
        return apiClient.post('api/cart/optimization/master_orders', params)
      },
      apply: {
        create(id) {
          return apiClient.post(
            `api/cart/optimization/master_orders/${id}/apply`
          )
        },
      },
    },
    rules: {
      create(params) {
        return apiClient.post('api/cart/optimization/rules', params)
      },
      index() {
        return apiClient.get('api/cart/optimization/rules')
      },
      update(id, params) {
        return apiClient.put(`api/cart/optimization/rules/${id}`, params)
      },
      delete(id) {
        return apiClient.delete(`api/cart/optimization/rules/${id}`)
      },
    },
  },
  optimization_type: {
    update(params) {
      return apiClient.put('api/cart/optimization_type', params)
    },
  },
  orders: {
    update(id, params) {
      return apiClient.put(`api/cart/orders/${id}`, params)
    },
    delete(id) {
      return apiClient.delete(`api/cart/orders/${id}`)
    },
    delivery_date: {
      update({ order_id, ...params }) {
        return apiClient.put(
          `api/cart/orders/${order_id}/delivery_date`,
          params
        )
      },
    },
    delivery_days: {
      index(order_id) {
        return apiClient.get(`api/cart/orders/${order_id}/delivery_days`)
      },
    },
    delivery_mode: {
      update(order_id, params) {
        return apiClient.put(
          `api/cart/orders/${order_id}/delivery_mode`,
          params
        )
      },
    },
    shipping_costs: {
      update(order_id, params) {
        return apiClient.put(
          `api/cart/orders/${order_id}/shipping_cost`,
          params
        )
      },
    },
  },
  masterOrders: {
    create(params) {
      return apiClient.post('api/cart/master_order', params)
    },
    update(params) {
      return apiClient.put('api/cart/master_order', params)
    },
  },
  templates: {
    index(params) {
      return apiClient.get('api/order_templates', { params })
    },
    create(params) {
      return apiClient.post('api/order_templates', params)
    },
    delete(id) {
      return apiClient.delete(`api/order_templates/${id}`)
    },
    update(id, params) {
      return apiClient.put(`api/order_templates/${id}`, params)
    },
    show(id, params) {
      return apiClient.get(`api/order_templates/${id}`, { params })
    },
    pdf: {
      index(params) {
        return apiClient.get('api/order_templates.pdf', {
          params,
          responseType: 'blob',
        })
      },
      show(id, params) {
        return apiClient.get(`api/order_templates/${id}.pdf`, {
          params,
          responseType: 'blob',
        })
      },
    },
    short: {
      show(id, params) {
        return apiClient.get(`api/order_templates/${id}/short`, { params })
      },
    },
    duplications: {
      create(params) {
        return apiClient.post('api/order_templates/duplications', params)
      },
    },
    master_orders: {
      create(params) {
        return apiClient.post('api/order_templates/master_order', params)
      },
      update(params) {
        return apiClient.put('api/order_templates/master_order', params)
      },
      delete(id) {
        return apiClient.delete(`api/cart/master_order/templates/${id}`)
      },
    },
    order_template_spree_prices: {
      update(id, params) {
        return apiClient.put(`api/order_template_spree_prices/${id}`, params)
      },
      delete(id, params) {
        return apiClient.delete(`api/order_template_spree_prices/${id}`, {
          data: params,
        })
      },
    },
    versions: {
      index(id, params) {
        return apiClient.get(`api/order_templates/${id}/versions`, { params })
      },
    },
  },
  verify_units: {
    create(params) {
      return apiClient.post(`api/cart/verify_units`, params)
    },
  },
}
