import dashboardApi from '@/services/api/dashboard'
import ordersApi from '@/services/api/orders'
import supplierTenantsApi from '@/services/api/supplier-tenants'
import { uniqBy } from 'lodash'

export const namespaced = true

export const state = {
  deliveries: {
    expected_count: 0,
    partially_count: 0,
    late_count: 0,
  },
  expiring_prices: {
    items_count: 0,
    suppliers_count: 0,
  },
  items: {
    most_bought: [],
    least_bought: [],
  },
  open_payments: {
    count: 0,
    amount: 0,
  },
  procurements: {
    total: '0.0',
    total_delta: '0.0',
    total_percent_delta: '0.0',
    organic_percent: '0.0',
    organic_percent_delta: null,
    regional_percent: '0.0',
    regional_percent_delta: null,
    organic_and_regional_percent: '0.0',
    organic_and_regional_percent_delta: null,
    other_and_shipping_percent: '0.0',
    other_and_shipping_percent_delta: null,
    top_suppliers: [],
  },
  sustainability: {
    investigated: {
      total_amount: '0.0',
      total_best_price_amount: '0.0',
      total_organic_amount: '0.0',
      total_regional_amount: '0.0',
    },
    compared: {
      total_amount: '0.0',
      total_best_price_amount: '0.0',
      total_organic_amount: '0.0',
      total_regional_amount: '0.0',
    },
  },
  total_orders: {
    chart: {
      labels: { count: [], money: [] },
      series: { count: [], money: [] },
      total_count: 0,
      active_count: 0,
    },
    infiniteId: +new Date(),
    orders: [],
    pages: 0,
    per_page: 0,
    total_count: 0,
    count: 0,
    current_page: 0,
  },
  total_orders_statistics: {
    chart_context: null,
    series: [],
    categories: [],
    orders: [],
    suppliers: [],
  },
  filters: {
    state: {
      value: [],
      loading: false,
      selected: false,
      options: [
        { name: 'canceled', type: 'state' },
        { name: 'draft', type: 'state' },
        { name: 'delivered', type: 'state' },
        { name: 'ordered', type: 'state' },
        { name: 'paid', type: 'state' },
        { name: 'order_failed', type: 'state' },
      ],
    },
    suppliers: {
      selected: false,
      value: [],
      loading: false,
      type: 'checkbox',
      options: [],
      withAsyncOptions: true,
    },
    group: {
      selected: false,
      value: { name: 'day', type: 'group' },
      type: 'radio',
      options: [
        { name: 'day', type: 'group' },
        { name: 'week', type: 'group' },
        { name: 'month', type: 'group' },
        { name: 'quarter', type: 'group' },
      ],
    },
    value_type: {
      selected: false,
      value: { name: 'amount', type: 'value_type' },
      type: 'radio',
      options: [
        { name: 'total', type: 'value_type' },
        { name: 'amount', type: 'value_type' },
      ],
    },
  },
}

export const getters = {
  appliedFilters: (state) => {
    const { filters } = state

    return [...filters.state.value, ...filters.suppliers.value].filter(
      (filterItem) => filterItem && filterItem.name
    )
  },
  isAnyStatusFilterPresent: (state) => {
    return state.filters.state.value.length > 0
  },
  isAnySuppliersFilterPresent: (state) => {
    return state.filters.suppliers.value.length > 0
  },
  isGrouped: (state) => {
    return (
      state.filters.group.value &&
      state.filters.group.value.name !== 'ungrouped'
    )
  },
  filterItems: (state) => {
    return Object.entries(state.filters).flatMap(([key, value]) => {
      return { name: key, ...value }
    })
  },
  filterOptionFor: (state) => (type, name) => {
    return state.filters[type].options.find((option) => option.name === name)
  },
  selectedPeriod: (state) => {
    return state.filters.group.value.name
  },
}

export const mutations = {
  CHANGE_ORDER_STATE(state, { id, status }) {
    const order = state.total_orders.orders.find((o) => o.id === id)
    order.full_supply_state = status
  },
  CLEAR_TOTAL_ORDERS(state) {
    state.total_orders.orders = []
    state.total_orders.current_page = 0
    state.total_orders.infiniteId += 1
  },
  DELETE_ORDER(state, order) {
    state.total_orders.orders = state.total_orders.orders.filter(
      (o) => o.id !== order.id
    )
    state.total_orders.chart.total_count =
      state.total_orders.chart.total_count - 1
  },
  REPLACE_ORDER(state, order) {
    state.total_orders.orders = state.total_orders.orders.map((existingOrder) =>
      existingOrder.id === order.id ? order : existingOrder
    )
  },
  SET_DELIVERIES_DATA(state, data) {
    state.deliveries.expected_count = data.expected_count
    state.deliveries.partially_count = data.partially_count
    state.deliveries.late_count = data.late_count
  },
  SET_EXPIRING_PRICES_DATA(state, data) {
    state.expiring_prices.items_count = data.items_count
    state.expiring_prices.suppliers_count = data.suppliers_count
  },
  SET_OPEN_PAYMENTS_DATA(state, data) {
    state.open_payments.count = data.count
    state.open_payments.amount = data.amount
  },
  SET_TOTAL_ORDERS_DATA(state, { orders, ...paginationMeta }) {
    state.total_orders.current_page += 1
    state.total_orders.total_count = paginationMeta.total_count
    state.total_orders.orders = uniqBy(
      [...state.total_orders.orders, ...orders],
      'id'
    )
  },
  SET_BAR_CHART_CONTEXT(state, chartContext) {
    state.total_orders_statistics.chart_context = chartContext
  },
  SET_DONUT_CHART_DATA(state, { chart }) {
    state.total_orders.chart = {
      series: {
        count: chart.data.map((item) => item.count),
        money: chart.data.map((item) => item.total),
      },
      labels: {
        count: chart.data.map((item) => item.count),
        money: chart.data.map((item) => item.total),
      },
      tooltips: chart.data.map((item) => item.state || item.payment_state),
      total_count: chart.meta.total_count,
      active_count: chart.meta.active_count,
    }
  },
  SET_TOTAL_ORDERS_STATISTICS_DATA(state, { chart: { series, categories } }) {
    state.total_orders_statistics.series = series
    state.total_orders_statistics.categories = categories
  },
  APPLY_FILTER(state, { type, item }) {
    if (type === 'group') {
      state.filters.group.value = item.name === 'ungrouped' ? null : item
      return
    }

    if (type === 'value_type') {
      state.filters.value_type.value = item
      return
    }

    if (state.filters[type].value.includes(item)) {
      state.filters[type].value = state.filters[type].value.filter(
        (existingItem) => {
          return existingItem !== item
        }
      )
    } else {
      state.filters[type].value.push(item)
    }
  },
  CLEAR_ALL_FILTERS(state) {
    const { filters } = state

    filters.state.value = []
    filters.suppliers.value = []
    filters.group.value = 'day'
  },
  CLEAR_FILTER(state, filterItem) {
    if (filterItem.type === 'group') {
      state.filters.group.value = 'day'

      return
    } else {
      state.filters[filterItem.type].value = state.filters[
        filterItem.type
      ].value.filter((item) => {
        return item.name !== filterItem.name
      })
    }
  },
  SET_FILTER_LOADING(state, { type, value }) {
    state.filters[type].loading = value
  },
  SET_FILTER_OPTIONS(state, { type, data }) {
    state.filters[type].options = data.suppliers.map((item) => {
      return {
        id: item.id,
        name: item.name,
        type,
      }
    })
  },
  SET_ITEMS_DATA(state, data) {
    state.items = data
  },
  SET_PROCUREMENTS(state, data) {
    state.procurements = data
  },
  SET_SUSTAINABILITY(state, data) {
    state.sustainability = data
  },
  TOGGLE_FILTER_ITEM(state, filterItem) {
    state.filters[filterItem.name].selected =
      !state.filters[filterItem.name].selected
  },
}

export const actions = {
  async fetchData({ state, commit }) {
    const expiringPricesResponse = await dashboardApi.expiring_prices.show({
      period: state.filters.group.value.name,
    })

    const deliveriesResponse = await dashboardApi.deliveries.show({
      period: state.filters.group.value.name,
    })

    const openPaymentsResponse = await dashboardApi.open_payments.show({
      period: state.filters.group.value.name,
    })

    const itemsResponse = await dashboardApi.items.show({
      period: state.filters.group.value.name,
    })

    commit('SET_EXPIRING_PRICES_DATA', expiringPricesResponse.data)
    commit('SET_DELIVERIES_DATA', deliveriesResponse.data)
    commit('SET_OPEN_PAYMENTS_DATA', openPaymentsResponse.data)
    commit('SET_ITEMS_DATA', itemsResponse.data)
  },
  async fetchTotalOrders({ state, commit }, infiniteLoaderContext) {
    const { current_page } = state.total_orders
    const startInfiniteId = state.total_orders.infiniteId

    try {
      const response = await dashboardApi.total_orders.show({
        group: state.filters.group.value.name,
        state: state.filters.state.value.map((item) => item.name).join(','),
        suppliers: state.filters.suppliers.value
          .map((item) => item.id)
          .join(','),
        page: current_page + 1,
      })
      if (response.data.pages <= current_page) {
        infiniteLoaderContext.complete()
      } else {
        infiniteLoaderContext.loaded()
      }

      const endInfiniteId = state.total_orders.infiniteId

      if (startInfiniteId === endInfiniteId)
        commit('SET_TOTAL_ORDERS_DATA', response.data)
    } catch (_error) {
      infiniteLoaderContext.error()
    }
  },
  async fetchTotalOrdersStatisticsData({ commit }) {
    const group = state.filters.group.value.name

    const response = await dashboardApi.total_orders.statistics.show({
      group,
      state: state.filters.state.value.map((item) => item.name).join(','),
      suppliers: state.filters.suppliers.value.map((item) => item.id).join(','),
      value_type: state.filters.value_type.value.name,
    })

    commit('SET_DONUT_CHART_DATA', response.data.donut)
    commit('SET_TOTAL_ORDERS_STATISTICS_DATA', response.data.bar)
  },
  async deleteOrder({ commit }, params) {
    const response = await ordersApi.delete(params)
    commit('DELETE_ORDER', response.data)
  },
  setOrderStatus({ commit }, { id, status }) {
    commit('CHANGE_ORDER_STATE', {
      id: id,
      status: status,
    })
  },
  async updateOrder({ commit }, { order_id, ...params }) {
    const response = await ordersApi.update(order_id, params)
    commit('REPLACE_ORDER', response.data)
  },
  setBarChartContext({ commit }, chartContext) {
    commit('SET_BAR_CHART_CONTEXT', chartContext)
  },
  async loadSuppliersFilterOptions({ commit }) {
    commit('SET_FILTER_LOADING', { value: true, type: 'suppliers' })

    const response = await supplierTenantsApi.index({ all: true })

    commit('SET_FILTER_OPTIONS', {
      data: response.data,
      type: 'suppliers',
    })

    commit('SET_FILTER_LOADING', { value: false, type: 'suppliers' })
  },
  async applyFilter({ dispatch, commit }, { type, item }) {
    await commit('APPLY_FILTER', { type, item })
    commit('CLEAR_TOTAL_ORDERS')

    dispatch('fetchData')
    dispatch('fetchTotalOrdersStatisticsData')
    dispatch('getProcurements')
    dispatch('getSustainability')
  },
  clearAllFilters({ dispatch, commit }) {
    commit('CLEAR_ALL_FILTERS')
    commit('CLEAR_TOTAL_ORDERS')

    dispatch('fetchData')
    dispatch('fetchTotalOrdersStatisticsData')
  },
  clearFilter({ dispatch, commit }, filterItem) {
    commit('CLEAR_FILTER', filterItem)
    commit('CLEAR_TOTAL_ORDERS')

    dispatch('fetchData')
    dispatch('fetchTotalOrdersStatisticsData')
  },
  clearTotalOrders({ commit }) {
    commit('CLEAR_TOTAL_ORDERS')
  },
  async getProcurements({ state, commit }) {
    const response = await dashboardApi.procurements.show({
      period: state.filters.group.value.name,
    })

    commit('SET_PROCUREMENTS', response.data)
  },
  async getSustainability({ state, commit }) {
    const response = await dashboardApi.sustainabilities.show({
      period: state.filters.group.value.name,
    })

    commit('SET_SUSTAINABILITY', response.data)
  },
  toggleFilterItem({ commit }, filterItem) {
    commit('TOGGLE_FILTER_ITEM', filterItem)
  },
}
